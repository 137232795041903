<template>
  <div>

    <b-row>
      <b-col md="12" class="mb-3 text-right">
        <b-button class="" variant="outline-primarySpore" @click="showFilter">
          <feather-icon icon="FilterIcon" class="mr-50"/>
          <span class="align-middle" v-if="!show"> Mostrar filtros </span>
          <span class="align-middle" v-else > Ocultar filtros </span>
        </b-button>
      </b-col>
    </b-row>

    <b-card no-body v-if="show">
      <b-card-header class="pb-50">
        <h5> Filtrar </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <b-form-group label="Nombre">
              <b-form-input id="name" v-model="filters.name"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 text-right">
            <b-button variant="primarySpore" @click="search">
              <feather-icon icon="SearchIcon" class="mr-50"/>
              <span>Buscar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col md="6" class="mb-3 text-left">
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostrar</label>
            <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
              <label>Registros</label>
          </b-col>
        </b-col>
        <b-col cols="12" md="6" class="custom-search d-flex justify-content-end">
          <div class="d-flex align-items-center">
            <b-button class="m-1" variant="primarySpore" :to="`/zones/create`" v-b-tooltip.hover.top title="Agregar">
              <feather-icon icon="PlusCircleIcon"/>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div id="data-list-list-view" class="data-list-container">
      <b-table :fields="fields" :items="responseZone" responsive tbody-tr-class="shadow-sm">
          <template #cell(name)="data">
            <listItem :value="data.item.name" description="Nombre de la zona" img="contact"/>
          </template>
          <template #cell(createdAt)="data">
            <listItem :value="format(data.item.createdAt)" />
          </template>
          <template #cell(uuid)="data">
            <b-row>
              <b-col cols="12" md="12" class="custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                  <b-button v-b-tooltip.hover.left title="Editar" variant="primarySpore" :to="`/zones/${data.value}/edit`">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </div>
                &nbsp;
                <div class="d-flex align-items-center">
                  <b-button v-b-tooltip.hover.top title="Eliminar" variant="danger" @click="confirmDelete(data.value)">
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </template>
      </b-table>

      <div v-if="responseZone.length == 0" class="noData">
        {{$t('Messages.NoData')}}
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                <template #first-text>
                  <feather-icon icon="ChevronsLeftIcon" size="18"/>
                </template>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
                <template #last-text>
                  <feather-icon icon="ChevronsRightIcon" size="18"/>
                </template>
              </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import listItem from '@core/spore-components/listItem/listItem.vue'
import { formatDateLanguages } from '@/libs/tools/helpers'
// import { empty } from '@/libs/tools/helpers'
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BTable, BPagination, BFormInput, BFormGroup
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardHeader, BCardBody, BButton, BRow, BCol, listItem, BTable, BPagination, BFormInput, BFormGroup
  },
  data () {
    return {
      show: false,
      responseZone: [],
      fields: [
        { key: 'name', sortable: true, label: 'Nombre' },
        { key: 'createdAt', sortable: true, label: 'Fecha creación' },
        { key: 'uuid', sortable: false, label: '' }
      ],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      count: 0,
      filters: {
        limit: 20,
        offset: 0,
        byColumn: 'name',
        byOrder: 'DESC',
        divisionUuid: this.$store.state.session.AppActiveUser().division.uuid,
        name: ''
      }
    }
  },

  methods: {
    showFilter () {
      this.show = !this.show
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    async getResponse () {
      const params = {
        url: 'zones/',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        console.log(data)
        this.responseZone = data.data
        this.totalRows = data.data.count
        this.count = data.data.length
      }).catch(() => {
        this.responseZone = []
      })
    },
    search () {
      this.filters.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    confirmDelete (uuid) {
      this.$swal({
        title: '¿Deseas eliminar esta zona?',
        text: 'Esperando confirmación del usuario',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `zones/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    }
  },

  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Shares.scss";
</style>
